import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
export interface Props {onClose : Function}
export const MainPopup:React.FC<Props> = ({onClose}) => {
    const [cookie, setCookies] = useCookies(['main_popup'])
    const [todayClose, setTodayClose] = useState<boolean>(false)
    const handleChange = (e:any) => {
        setTodayClose(e.target.checked)
    }
    const handleClose = () => {
        if(todayClose){
            const expirationDate = new Date()
            expirationDate.setHours(expirationDate.getHours()+24)
            setCookies('main_popup', true, {expires : expirationDate})
            onClose()
        }else{onClose()}
    }
    const navigate = useNavigate()
    return(
        <>
        <div className="mainPopUp">
            <div>
                <div>
                    <h3>
                        향정신성의약품 식욕억제제의 위해성 완화 <br></br>
                        조치방법 일환으로 안내서를 배부하고 있습니다.
                    </h3>
                </div>
                <div>
                    <p>
                        당사 해당 제품은 아래와 같습니다.
                        <strong>전문가용 안내서 및 체크리스트, 환자용 안내서를 확인해 보시기 바랍니다.</strong>
                    </p>
                    <ul>
                        <li>■ 디피온정(디에틸프로피온염산염)</li>
                        <li>■ 펜디씬정(펜디메트라진타르타르산염)</li>
                        <li>■ 펜트민정(펜터민염산염)</li>
                    </ul>
                    <div>
                        <button onClick={()=>navigate('/syntheticMedicine/productAnnouncement/obesityTreatmentMedicine/304')}>전문가용 안내서 및 체크리스트</button>
                        <button onClick={()=>navigate('/syntheticMedicine/productAnnouncement/obesityTreatmentMedicine/303')}>환자용 안내서</button>
                    </div>
                </div>
            </div>
            <div className="btnBox">
                <div>
                    <input type="checkbox" name="" onChange={handleChange} checked={todayClose} id="today_close"/>
                    <label htmlFor="today_close">오늘 하루 열지 않음</label>
                </div>
                <button onClick={handleClose}>닫기</button>
            </div>
        </div>
        </>
    )
}