import React, { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../util/cookie/cookies";
import { MainPopup } from "./mainPopup";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Main = () => {
    
    const lang = getCookie('BINEXLANG')
    const sliderRef = useRef<any>(null)
    const navigate = useNavigate()
    const main_popup = getCookie('main_popup')
    const [state , setState] = useState<number>(1)
    const [pause, setPause] = useState<boolean>(false)
    const [isOpen, setIsOpen] = useState<boolean>(true)
    const handleSlickNext = useCallback(()=>{sliderRef.current.slickNext()},[])
    const handleSlickPrev = useCallback(()=>{sliderRef.current.slickPrev()},[])
    const handleClose = () => {setIsOpen(false)}
    const handleSlider = (status : string) => {
        if(status === 'prev'){
            if(state === 1){setState(3)} else{setState(state-1)}
            handleSlickPrev()
        }
        else {
            if(state === 3){setState(1)} else{setState(state+1)}
            handleSlickNext()
        }
    }
    const handlePause = () => {setPause(prev => !prev)}
    const handleTransPage = () => {
        if(state === 1){navigate('/binex/about/overView')}
        else if(state === 2){navigate('/bioMedicine/bussinessOverview')}
        else if(state === 3){navigate('/syntheticMedicine/search/productname')}
    }
    const handleGoToSlick = (index:number) => {
        if(sliderRef.current){
            setState(index+1)
            sliderRef.current.slickGoTo(index)
        }
    }
    useEffect(() => {
        let intervalId: NodeJS.Timeout;
        if (!pause) {
            intervalId = setInterval(() => {
                handleSlider('next');
            },  5050);
        }
        return () => {clearInterval(intervalId);};
    }, [pause, state]);
    
    const setting = {
        dots : false,
        arrows : false,
        draggable : false,
        slidesToScroll: 1,
        slidesToShow: 1,
        vertical: false,
        autoplay: pause,
        autoplaySpeed: 5000,
    }
    console.log(sliderRef.current)
    return(
        <>
         <div className="mainPage">
            <div>
                <button className="circleBtn" onClick={handleTransPage}>
                    <i className="fa-regular fa-plus"></i>
                    <img src={`/assets/images/main_circleBtn${state}.png`} alt="circle text" />
                </button>
                <div className="sliderWrap">
                    <div className={'active'}>
                        <div className="left">
                        <Slider {...setting} ref={sliderRef}>
                            <div className="imgBox" id="1">
                                <img src={`/assets/images/mainSlider_1.png`} alt="Bacillus Polyfermenticus"/>
                            </div>
                            <div className="imgBox" id="2">
                                <img src={`/assets/images/mainSlider_2.png`} alt="Bacillus Polyfermenticus"/> 
                            </div>
                            <div className="imgBox" id="3">
                                <img src={`/assets/images/mainSlider_3.png`} alt="Bacillus Polyfermenticus"/>
                            </div>
                        </Slider>
                        </div>
                        <div className="right">
                            <div>
                                {state === 1 ? 
                                <>
                                    <div>
                                        <div className="slider_txt_wrap1">
                                            <h2 className="slider_txt">
                                                Reliable
                                                <span>Healthcare Partner</span>
                                            </h2>
                                        </div>
                                        {lang === 'Kr' ? 
                                        <div className="slider_txt_wrap2">
                                            <p className="slider_txt">
                                                케미칼에서 차세대 바이오의약품까지 신뢰할 수 있는 헬스케어 파트너, <br></br>
                                                 혁신을 통해 고객의 건강한 미래를 만들어가는 제약기업, 바이넥스입니다.
                                            </p>
                                        </div>
                                        : 
                                        <div className="slider_txt_wrap2">
                                            <p className="slider_txt">
                                                A trusted healthcare partner encompassing from chemical drugs to next-generation biologics. <br/>
                                                Building a healthier future for our customer through innovation.
                                            </p>
                                        </div>
                                        }
                                    </div>
                                </> : ''
                                }

                                {state === 2 ? 
                                <>
                                    <div>
                                        <div className="slider_txt_wrap1">
                                            <h2 className="slider_txt">
                                                Biologics
                                                <span>CDMO Services</span>
                                            </h2>
                                        </div>
                                        {lang === 'Kr' ? 
                                        <div className="slider_txt_wrap2">
                                            <p className="slider_txt">
                                                바이넥스는 바이오의약품의 성공적인 임상 및 시장 진출을 위한<br></br>
                                                <strong> 전문성과 경험을 갖춘 CMC 파트너</strong>입니다.
                                            </p>
                                        </div> : 
                                        <div className="slider_txt_wrap2">
                                            <p className="slider_txt">
                                                BINEX is a reliable <strong>CDMO partner</strong> with expertise and experience for the successful clinical and market entry of biologics.
                                            </p>
                                        </div>
                                        }
                                    </div>
                                </> : ''
                                }

                                {state === 3 ? 
                                <>
                                    <div>
                                        <div className="slider_txt_wrap1">
                                            <h2 className="slider_txt">
                                                Creating a
                                                <span>Healthier World</span>
                                            </h2>
                                        </div>
                                    
                                        {lang === 'Kr' ? 
                                        <div className="slider_txt_wrap2">
                                            <p className="slider_txt">
                                                반세기 넘게 이어온 의약품 제조업의 노하우로 <br></br>
                                                건강한 세상을 만들어 갑니다.
                                            </p>
                                        </div>: 
                                        <div className="slider_txt_wrap2">
                                            <p className="slider_txt">
                                                Building a healthier world through BINEX’s accumulated manufacturing expertise for almost a century.
                                            </p>
                                        </div>
                                        }
                                    </div>
                                </> : ''
                                }
        
                                <div className="dotsBox">
                                    <ol>
                                        <li className={state===1?'active':''} onClick={()=>handleGoToSlick(0)}></li>
                                        <li className={state===2?'active':''} onClick={()=>handleGoToSlick(1)}></li>
                                        <li className={state===3?'active':''} onClick={()=>handleGoToSlick(2)}></li>
                                    </ol>
                                    <div>
                                        <button onClick={()=>handleSlider('prev')}><i className="fa-regular fa-angle-left"></i></button>
                                        <button onClick={()=>handleSlider('next')}><i className="fa-regular fa-angle-right"></i></button>
                                        <button className={pause ? 'controlBtn active' :'controlBtn'} onClick={handlePause}></button>
                                        {/* <!-- 재생버튼 토글 필요. active클래스 --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {isOpen && !main_popup && lang ==='Kr' ? <MainPopup onClose={handleClose}/> : ''}
        </>
    )
}
