import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
export interface Props {data:any}
export const InquiryList:React.FC<Props> = ({data}) => {
    const navigate = useNavigate()
    const groupId = sessionStorage.getItem("GroupCode");
    const [groupData, setGroupData] = useState<any>([])
    useEffect(()=>{
        switch(groupId){
            case '16' : 
            const find16Data = data?.filter((item : any)=>item.inquiry_type === "제품문의" || item.inquiry_type === "수탁문의")
            if(find16Data){setGroupData(find16Data)}; break;
            case '17' :
            const find17Data = data?.filter((item : any)=>item.inquiry_type === "투자문의")
            if(find17Data){setGroupData(find17Data)}; break;
            case '19' :
            const find19Data = data?.filter((item : any)=>item.inquiry_type === "CDMO문의")
            if(find19Data){setGroupData(find19Data)}; break;
            case '20' :
            const find20Data = data?.filter((item : any)=>item.inquiry_type === "채용문의")
            if(find20Data){setGroupData(find20Data)}; break;
            default : setGroupData(data)
        }
    }, [data])
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
            <tr className="active" key={list?.inquiry_idx} onClick={()=>navigate(`/dotsAdmin/reporting/inquiry/${list?.inquiry_idx}`)}>
                
                <td>{list?.inquiry_company_name}</td>
                
                <td>{list?.inquiry_name}</td>

                <td dangerouslySetInnerHTML={{__html : list?.inquiry_type}}></td>

                <td>{list?.inquiry_email}</td>

                <td>{list?.inquiry_phone}</td>

                <td>{list?.inquiry_mdate}</td>

                <td>{list?.inquiry_reply_status === 'U' ? '답변대기' : '답변완료'}</td>

            </tr>
            ))}
    
        </tbody>
        </>
    )
}